import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Icon } from "@atoms";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { usePlayerContext, Player, Audio } from "@vime/react";
import useDevice from "@hooks/useDevice";

const AudioPlayer = () => {
  const device = useDevice();
  const [{ audio }, dispatch] = useAppState();
  const { play, url, global, title } = audio;

  const [progress, setProgress] = useState(0);
  const [showing, setShowing] = useState(false);

  const ref = useRef();

  // eslint-disable-next-line no-unused-vars
  const [currentTime, setCurrenTime] = usePlayerContext(ref, "currentTime", 0);
  const [duration] = usePlayerContext(ref, "duration", -1);
  const [playing] = usePlayerContext(ref, "playing", false);
  const [isPlaybackReady] = usePlayerContext(ref, "playbackReady", false);
  const [paused, setPaused] = usePlayerContext(ref, "paused", true);

  useEffect(() => {
    setProgress(currentTime / duration);
  }, [currentTime]);

  useEffect(() => {
    if (global && url) {
      setShowing(true);
    } else {
      setShowing(false);
    }
  }, [global, play, url, duration]);

  useEffect(() => {
    if (isPlaybackReady && play && device !== "iOS") {
      setPaused(false);
    }
  }, [isPlaybackReady]);

  useEffect(() => {
    dispatch({ type: paused ? "pauseAudio" : "playAudio" });
  }, [paused]);

  useEffect(() => {
    if (play) {
      setPaused(false);
    } else if (isPlaybackReady) {
      setPaused(true);
    }
  }, [play]);

  return (
    <div
      className={classNames(
        "fixed bottom-0 left-0 right-0 z-[950] bg-black p-3 text-white transition duration-200",
        {
          "translate-y-full": !showing,
          "pointer-events-none": duration === -1,
        }
      )}
    >
      <div className="h-0 w-0">
        <Player ref={ref}>
          {url && (
            <Audio>
              <source key={url} data-src={url} type="audio/mp4" />
              {/* <source> and <track> elements are placed here. */}
            </Audio>
          )}
        </Player>
      </div>
      <div className=" flex items-center gap-3">
        <button
          className="relative h-6 w-6 flex-shrink-0 rounded-full border-2 border-white p-1 sm:h-8 sm:w-8"
          type="button"
          onClick={() => setPaused(!paused)}
        >
          <span className="sr-only">{playing ? "pause" : "play"}</span>
          <Icon
            name="play"
            fitHeight
            className={classNames(
              "absolute inset-0 p-1 transition duration-100",
              {
                "opacity-0": playing || duration === -1,
              }
            )}
          />
          <Icon
            name="pause"
            fitHeight
            className={classNames(
              "stroke absolute inset-0 stroke-current p-1.5 transition duration-100",
              {
                "opacity-0": !playing || duration === -1,
              }
            )}
          />
          <Icon
            name="loading"
            fitHeight
            className={classNames(
              "stroke absolute inset-0 animate-spin stroke-current p-1 transition duration-100",
              {
                "opacity-0": duration !== -1,
              }
            )}
          />
        </button>
        <div className="flex flex-grow flex-col gap-2">
          <div
            className={classNames("flex justify-between gap-3", {
              "animate-pulse": duration === -1,
            })}
          >
            {title && (
              <div>
                <p className="text-xs font-bold">{title.split(" - ")[0]}</p>
                <p className="text-xs">{title.split(" - ")[1]}</p>
              </div>
            )}
            {duration !== -1 && (
              <div className="text-xs tabular-nums">
                {`${(currentTime / 60).toFixed(0)}:${(currentTime % 60)
                  .toFixed(0)
                  .padStart(2, 0)}`}
                /
                {`${(duration / 60).toFixed(0)}:${(duration % 60)
                  .toFixed(0)
                  .padStart(2, 0)}`}
              </div>
            )}
          </div>
          <div className="relative h-1 flex-grow bg-white/30">
            <m.div
              className="absolute left-0 top-0 bottom-0 w-full origin-left bg-coral"
              animate={{ scaleX: progress }}
            />
          </div>
        </div>
        <button
          className="relative h-6 w-6 rounded-full p-1"
          type="button"
          onClick={() => dispatch({ type: "stopAudio" })}
        >
          <Icon
            name="close"
            fitHeight
            className={classNames(
              "absolute inset-0 stroke-2 p-1.5 transition duration-100"
            )}
          />
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;
